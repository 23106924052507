<template>
  <div class="w-100">
    <v-card outlined class="mb-4">
      <v-card-title> Matterport 3D model </v-card-title>
      <v-card-text>
        <div class="mb-4">
          If you have a Matterport model of your location, add the public link here. If you don't know how to share your
          public link,
          <a href="https://matterport.com/blog/new-way-sharing-your-spaces" target="_blank">click here</a> (choose an
          unbranded option). Only share your model if you're comfortable with anyone in the world seeing a 3D
          walk-around of your location.
        </div>

        <v-row>
          <v-col cols="12" sm="6">
            <vx-input
              v-model="space.matterPortId"
              type="text"
              :rules="{ regex: REGEX.MATTERPORT_URL }"
              label="Matterport Link"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row :key="'a' + key">
      <v-col class="px-2" cols="12" sm="6" @drop.prevent="$event => drop($event, 0)" @dragover.prevent>
        <div v-if="breakpoint.xs" class="py-2"></div>
        <div
          class="solid-border cursor-pointer"
          draggable
          style="height: 100%; min-height: 150px"
          @dragstart="dragStart(0)"
          @dragend="dragEnd()"
          @click="$refs.file.click()"
        >
          <div v-if="$venueHasImage(space, 0)">
            <vx-img rectangle :src="$getVenueImage(space, '1600', 0)" @click.stop="openModal(0)">
              <vx-btn class="remove-photo" icon @click.stop="removeFile(0)">
                <vx-icon color="error">mdi-delete</vx-icon>
              </vx-btn>
            </vx-img>
          </div>

          <vx-img v-else rectangle>
            <div class="text-h2 center opacity-25">1</div>
          </vx-img>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-row>
          <v-col
            v-for="index in grid[0]"
            :key="index"
            cols="6"
            sm="6"
            md="6"
            @drop.prevent="$event => drop($event, index)"
            @dragover.prevent
          >
            <div
              class="solid-border cursor-pointer"
              draggable
              @dragstart="dragStart(index)"
              @dragend="dragEnd()"
              @click="$refs.file.click()"
            >
              <div v-if="$venueHasImage(space, index)">
                <vx-img
                  rectangle
                  :alt="space.name"
                  :src="$getVenueImage(space, '1600', index)"
                  @click.stop="openModal(index)"
                >
                  <vx-btn class="remove-photo" icon @click.stop="removeFile(index)">
                    <vx-icon color="error">mdi-delete</vx-icon>
                  </vx-btn>
                </vx-img>
              </div>

              <vx-img v-else rectangle>
                <div class="text-h2 center opacity-25">{{ index + 1 }}</div>
              </vx-img>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row :key="'b' + key">
      <v-col
        v-for="index in grid[1]"
        :key="index"
        cols="6"
        sm="3"
        md="3"
        @drop.prevent="$event => drop($event, index)"
        @dragover.prevent
      >
        <div
          class="dashed-border cursor-pointer"
          draggable
          @dragstart="dragStart(index)"
          @dragend="dragEnd()"
          @click="$refs.file.click()"
        >
          <div v-if="$venueHasImage(space, index)">
            <vx-img
              rectangle
              :alt="space.name"
              :src="$getVenueImage(space, '1600', index)"
              @click.stop="openModal(index)"
            >
              <vx-btn class="remove-photo" icon @click.stop="removeFile(index)">
                <vx-icon color="error">mdi-delete</vx-icon>
              </vx-btn>
            </vx-img>
          </div>
          <vx-img v-else rectangle>
            <div class="text-h2 center opacity-25">{{ index + 1 }}</div>
          </vx-img>
        </div>
      </v-col>
    </v-row>

    <!-- TODO: make saperate component -->
    <input
      ref="file"
      type="file"
      class="d-none"
      multiple
      accept=".jpg,.jpeg,.png,.webp,.avif,.tiff,.gif,.svg"
      onclick="this.value = null;"
      @change="changeFile()"
    />

    <lightbox
      full-screen
      close-on-click-outside-mobile
      slideshow-color-bar="#D02630"
      :items="items"
      :index="lightboxIndex"
      @close="lightboxIndex = null"
    />
  </div>
</template>

<script>
import { REGEX } from '@tutti/constants';
import { MediaService } from '@tutti/services';

import lightbox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { VENUE_TYPE } from '@tutti/constants';

export default {
  name: 'SpacePhoto',
  components: {
    lightbox,
  },
  props: {
    space: { type: Object, required: true },
    venueType: { type: String, required: true },
  },
  data() {
    return {
      REGEX,
      key: 0,
      lightboxIndex: null,
      currentIndex: null,
      grid: [
        [1, 2, 3, 4],
        [5, 6, 7, 8, 9, 10, 11, 12],
      ],
      isDragging: false,
    };
  },
  computed: {
    items() {
      return this.space.media.map((media, index) => this.$getVenueImage(this.space, 'compressed', index));
    },
  },
  methods: {
    openModal(index) {
      this.lightboxIndex = index;
    },
    dragStart(index) {
      this.currentIndex = index;
      this.isDragging = true;
    },
    dragEnd() {
      this.currentIndex = null;
      this.isDragging = false;
      this.key++;
    },
    drop(event, index) {
      if (this.isDragging) {
        const [image] = this.space.media.splice(this.currentIndex, 1);
        if (image) {
          this.space.media.splice(index, 0, image);
          this.isDragging = false;
        }
      } else {
        this.addFiles(event.dataTransfer.files);
      }
    },
    removeFile(index) {
      const media = this.space.media[index];
      if (media) {
        this.space.deletedMediaIds.push(media.key);
      }

      this.space.media.splice(index, 1);
      this.space._media.splice(index, 1);
      this.key++;
    },
    addFiles(files) {
      if (files) {
        // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
        [...files].forEach(file => {
          const length = this.space.media.length;

          if (length < 13) {
            file.tempURL = URL.createObjectURL(file);
            file.key = this.space.getTimestamp();
            if (this.venueType === VENUE_TYPE.LOCATION) {
              file.locationId = this.space._id;
            } else if (this.venueType === VENUE_TYPE.SPACE) {
              file.spaceId = this.space._id;
            }
            file.accountId = this.space.account?._id || this.$account?._id;

            this.space.media.push(file);
            this.space._media.push(file);
            this.upload(file);
          }
        });
      }
    },
    async upload(file) {
      file.uploading = true;
      this.$emit('uploaded');

      const response = await MediaService.upload(file, 'space');
      if (response) {
        file.url = response.data.url;
      }

      file.uploading = false;
      this.$emit('uploaded');
    },
    changeFile() {
      this.addFiles(this.$refs.file.files);
    },
    dropFile(event) {
      this.addFiles(event.dataTransfer.files);
    },
  },
};
</script>

<style scoped>
.dashed-border {
  border: 2px dashed #eeeeee;
}
.solid-border {
  border: 2px solid #eeeeee;
}
.dropzone:hover {
  background-color: #f6f6f6;
  cursor: pointer;
}
.remove-photo {
  position: absolute;
  right: 0;
  z-index: 1;
}
</style>
