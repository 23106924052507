var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" Matterport 3D model ")]), _c('v-card-text', [_c('div', {
    staticClass: "mb-4"
  }, [_vm._v(" If you have a Matterport model of your location, add the public link here. If you don't know how to share your public link, "), _c('a', {
    attrs: {
      "href": "https://matterport.com/blog/new-way-sharing-your-spaces",
      "target": "_blank"
    }
  }, [_vm._v("click here")]), _vm._v(" (choose an unbranded option). Only share your model if you're comfortable with anyone in the world seeing a 3D walk-around of your location. ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "rules": {
        regex: _vm.REGEX.MATTERPORT_URL
      },
      "label": "Matterport Link"
    },
    model: {
      value: _vm.space.matterPortId,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "matterPortId", $$v);
      },
      expression: "space.matterPortId"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    key: 'a' + _vm.key
  }, [_c('v-col', {
    staticClass: "px-2",
    attrs: {
      "cols": "12",
      "sm": "6"
    },
    on: {
      "drop": function drop($event) {
        $event.preventDefault();
        return function ($event) {
          return _vm.drop($event, 0);
        }.apply(null, arguments);
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
      }
    }
  }, [_vm.breakpoint.xs ? _c('div', {
    staticClass: "py-2"
  }) : _vm._e(), _c('div', {
    staticClass: "solid-border cursor-pointer",
    staticStyle: {
      "height": "100%",
      "min-height": "150px"
    },
    attrs: {
      "draggable": ""
    },
    on: {
      "dragstart": function dragstart($event) {
        return _vm.dragStart(0);
      },
      "dragend": function dragend($event) {
        return _vm.dragEnd();
      },
      "click": function click($event) {
        return _vm.$refs.file.click();
      }
    }
  }, [_vm.$venueHasImage(_vm.space, 0) ? _c('div', [_c('vx-img', {
    attrs: {
      "rectangle": "",
      "src": _vm.$getVenueImage(_vm.space, '1600', 0)
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openModal(0);
      }
    }
  }, [_c('vx-btn', {
    staticClass: "remove-photo",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.removeFile(0);
      }
    }
  }, [_c('vx-icon', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-delete")])], 1)], 1)], 1) : _c('vx-img', {
    attrs: {
      "rectangle": ""
    }
  }, [_c('div', {
    staticClass: "text-h2 center opacity-25"
  }, [_vm._v("1")])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-row', _vm._l(_vm.grid[0], function (index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "sm": "6",
        "md": "6"
      },
      on: {
        "drop": function drop($event) {
          $event.preventDefault();
          return function ($event) {
            return _vm.drop($event, index);
          }.apply(null, arguments);
        },
        "dragover": function dragover($event) {
          $event.preventDefault();
        }
      }
    }, [_c('div', {
      staticClass: "solid-border cursor-pointer",
      attrs: {
        "draggable": ""
      },
      on: {
        "dragstart": function dragstart($event) {
          return _vm.dragStart(index);
        },
        "dragend": function dragend($event) {
          return _vm.dragEnd();
        },
        "click": function click($event) {
          return _vm.$refs.file.click();
        }
      }
    }, [_vm.$venueHasImage(_vm.space, index) ? _c('div', [_c('vx-img', {
      attrs: {
        "rectangle": "",
        "alt": _vm.space.name,
        "src": _vm.$getVenueImage(_vm.space, '1600', index)
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.openModal(index);
        }
      }
    }, [_c('vx-btn', {
      staticClass: "remove-photo",
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.removeFile(index);
        }
      }
    }, [_c('vx-icon', {
      attrs: {
        "color": "error"
      }
    }, [_vm._v("mdi-delete")])], 1)], 1)], 1) : _c('vx-img', {
      attrs: {
        "rectangle": ""
      }
    }, [_c('div', {
      staticClass: "text-h2 center opacity-25"
    }, [_vm._v(_vm._s(index + 1))])])], 1)]);
  }), 1)], 1)], 1), _c('v-row', {
    key: 'b' + _vm.key
  }, _vm._l(_vm.grid[1], function (index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "sm": "3",
        "md": "3"
      },
      on: {
        "drop": function drop($event) {
          $event.preventDefault();
          return function ($event) {
            return _vm.drop($event, index);
          }.apply(null, arguments);
        },
        "dragover": function dragover($event) {
          $event.preventDefault();
        }
      }
    }, [_c('div', {
      staticClass: "dashed-border cursor-pointer",
      attrs: {
        "draggable": ""
      },
      on: {
        "dragstart": function dragstart($event) {
          return _vm.dragStart(index);
        },
        "dragend": function dragend($event) {
          return _vm.dragEnd();
        },
        "click": function click($event) {
          return _vm.$refs.file.click();
        }
      }
    }, [_vm.$venueHasImage(_vm.space, index) ? _c('div', [_c('vx-img', {
      attrs: {
        "rectangle": "",
        "alt": _vm.space.name,
        "src": _vm.$getVenueImage(_vm.space, '1600', index)
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.openModal(index);
        }
      }
    }, [_c('vx-btn', {
      staticClass: "remove-photo",
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.removeFile(index);
        }
      }
    }, [_c('vx-icon', {
      attrs: {
        "color": "error"
      }
    }, [_vm._v("mdi-delete")])], 1)], 1)], 1) : _c('vx-img', {
      attrs: {
        "rectangle": ""
      }
    }, [_c('div', {
      staticClass: "text-h2 center opacity-25"
    }, [_vm._v(_vm._s(index + 1))])])], 1)]);
  }), 1), _c('input', {
    ref: "file",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "multiple": "",
      "accept": ".jpg,.jpeg,.png,.webp,.avif,.tiff,.gif,.svg",
      "onclick": "this.value = null;"
    },
    on: {
      "change": function change($event) {
        return _vm.changeFile();
      }
    }
  }), _c('lightbox', {
    attrs: {
      "full-screen": "",
      "close-on-click-outside-mobile": "",
      "slideshow-color-bar": "#D02630",
      "items": _vm.items,
      "index": _vm.lightboxIndex
    },
    on: {
      "close": function close($event) {
        _vm.lightboxIndex = null;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }