<template>
  <div>
    <v-card outlined class="mb-4">
      <v-card-text>
        <section>
          <div class="mb-4">
            <label class="font-bold">Select your pricing model*</label>
          </div>

          <v-row>
            <v-col cols="12" sm="3">
              <vx-input
                v-model="space.priceModel"
                type="select"
                name="Price Model"
                :items="PRICE_MODELS"
                :rules="{ required: space.bookingVenue !== 'space' }"
                @change="$emit('autoSave')"
              />
            </v-col>
          </v-row>
        </section>

        <!-- List your prices below -->
        <section v-if="space.priceModel == 'fixed'">
          <div class="mb-4">
            <label class="font-bold">List your prices below</label>
            <p class="mb-0">You can have up to 5 prices. Simply add or delete lines as necessary.</p>
            <!-- <p class="text-caption mb-4">*You must charge more than £2.50 for a paid booking.</p> -->
          </div>

          <v-row v-for="(price, index) in space.prices" :key="index">
            <v-col cols="10" sm="4" md="2" class="d-flex pb-0">
              <vx-input v-model="price.enabled" type="checkbox" class="mt-2" hide-details dense />

              <vx-input
                v-model="price.value"
                type="number"
                label="Price"
                :rules="{ required: price.enabled && space.bookingVenue !== 'space', minPrice: { price } }"
                prefix="£"
                :clearable="false"
                :disabled="!price.enabled"
                @change="space.onPriceChange(price)"
              />
            </v-col>

            <v-col cols="10" sm="6" md="2" class="d-flex pb-0">
              <span class="mr-5 mt-2">per</span>
              <vx-input
                type="text"
                class="w-25"
                outlined
                :value="DURATION_TYPES.find(type => type.value === price.type).text"
                dense
                hide-details
                readonly
                :disabled="!price.enabled"
              />
            </v-col>
            <v-col v-if="price.type !== 'hour'" cols="10" sm="6" md="4" class="d-flex pb-0">
              <span class="mr-5 mt-2">This is discount of</span>
              <vx-input
                v-model="price.discount"
                type="number"
                outlined
                dense
                label="Discount"
                :clearable="false"
                :disabled="!price.enabled"
                suffix="%"
                @change="space.calculatePrice(price, $event)"
              />
            </v-col>

            <v-col v-if="breakpoint.xs && index < space.prices.length - 1" cols="12" class="mb-4">
              <v-divider />
            </v-col>
          </v-row>
        </section>

        <section v-if="space.priceModel == 'quote'">
          <div class="mb-4">
            <label class="font-bold">Minimum spend (public)</label>
            <p class="mb-0">
              Artists will be more likely to message if they have a rough idea of the minimum they’d be expected to
              spend and how much that would get them. (ex: £500 for 3 hours; £2000 for 2 days). This will be public to
              users.
            </p>
          </div>

          <v-row v-for="(price, index) in space.minimumPrices" :key="'minPrice' + index">
            <v-col cols="4" sm="3" md="2" class="pb-0">
              <vx-input v-model="price.value" type="number" label="Cost" prefix="£" @change="$emit('autoSave')" />
            </v-col>
            <v-col cols="4" sm="3" md="2" class="pb-0">
              <span v-if="breakpoint.mdAndUp" class="mr-2 mt-2 float-left">For :</span>
              <vx-input v-model="price.count" type="number" prefix="#" @change="$emit('autoSave')" />
            </v-col>
            <v-col cols="4" sm="3" md="2" class="pb-0">
              <vx-input
                v-model="price.type"
                type="select"
                label="Time"
                :items="space.getTimeOptions(price.type, space.minimumPrices)"
                @change="$emit('autoSave')"
              />
            </v-col>
          </v-row>

          <br />

          <div class="mb-4">
            <label class="font-bold">Rough price range (private)</label>
            <p class="mb-0">
              Price range allows us to show your space when a user sorts by price. We will not share these values with
              artists.
            </p>
          </div>

          <v-row v-for="(price, index) in space.roughPrices" :key="index">
            <v-col cols="12" sm="3" md="2" class="pb-0">
              <vx-input
                v-model="price.type"
                type="select"
                label="Time"
                :items="space.getTimeOptions(price.type, space.roughPrices)"
                @change="$emit('autoSave')"
              />
            </v-col>

            <v-col cols="5" sm="3" md="2" class="pb-0">
              <span v-if="breakpoint.mdAndUp" class="mr-2 mt-2 float-left">:</span>

              <vx-input
                v-model="price.min"
                type="number"
                label="Min"
                prefix="£"
                :rules="{ dynamic_max: { price } }"
                @change="$emit('autoSave')"
              />
            </v-col>

            <v-col cols="5" sm="3" md="2" class="pb-0">
              <span class="mr-2 mt-2 float-left">-</span>

              <vx-input
                v-model="price.max"
                type="number"
                label="Max"
                prefix="£"
                :rules="{ dynamic_min: { price } }"
                @change="$emit('autoSave')"
              />
            </v-col>

            <v-col cols="2" sm="2" md="2" class="px-0 pb-0 d-flex">
              <vx-btn
                v-if="space.roughPrices.length > 1"
                icon
                :small="breakpoint.mdAndDown"
                @click="space.removePrice(space.roughPrices, index)"
              >
                <vx-icon>mdi-delete</vx-icon>
              </vx-btn>
              <vx-btn
                v-if="
                  space.roughPrices.length == 1 ||
                  (space.roughPrices.length == index + 1 && space.roughPrices.length < 5)
                "
                icon
                :small="breakpoint.mdAndDown"
                color="primary"
                @click="space.addPrice(space.roughPrices)"
              >
                <vx-icon>mdi-plus</vx-icon>
              </vx-btn>
            </v-col>

            <v-col v-if="breakpoint.xs && index < space.roughPrices.length - 1" cols="12" class="mb-4">
              <v-divider />
            </v-col>
          </v-row>
        </section>
      </v-card-text>
    </v-card>

    <v-card outlined class="mb-4">
      <v-card-text>
        <div class="mb-4">
          <label class="font-bold">Overtime charge</label>
          <p class="mb-0">
            How much do you charge if an artist goes over the agreed time in your space? (If this happens you will have
            to charge them directly, but this information is just to keep potential hirers informed ahead of time).
          </p>
        </div>

        <v-row v-for="(price, index) in space.overtimeCharges" :key="index">
          <v-col cols="12" sm="4" md="2" class="pb-0">
            <vx-input v-model="price.value" type="number" label="Price" prefix="£" @change="$emit('autoSave')" />
          </v-col>

          <v-col cols="12" sm="4" md="2" class="d-flex pb-0">
            <span class="mr-5 mt-2">per</span>

            <vx-input
              v-model="price.type"
              type="select"
              class="w-100"
              label="Time"
              :items="space.getTimeOptions(price.type, space.overtimeCharges)"
              :rules="{ required: space.bookingVenue !== 'space' }"
              @change="$emit('autoSave')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card outlined class="mb-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" class="pb-0">
            <section>
              <label>Minimum booking time</label>
              <div class="d-flex mt-1">
                <vx-input
                  v-model="space.minBookingTimes[0].value"
                  type="number"
                  name="Hours"
                  :rules="{ min_value: 1 }"
                  @change="$emit('autoSave')"
                />
                <span class="mr-2 mt-2 ml-2">:</span>
                <vx-input
                  v-model="space.minBookingTimes[0].type"
                  type="select"
                  class="w-50"
                  :items="space.getTimeOptions(space.minBookingTimes[0].type, [{ type: 'half-day' }])"
                  hide-details
                  @change="$emit('autoSave')"
                />
              </div>
            </section>
          </v-col>

          <v-col cols="12" sm="2" class="pb-0">
            <vx-input
              v-model="space.minNoticeDays"
              type="number"
              title="Minimum days notice"
              name="Minimum days"
              :rules="{ min_value: 1 }"
              @change="$emit('autoSave')"
            />
          </v-col>

          <v-col cols="12" sm="2" class="pb-0">
            <vx-input
              v-model="space.maxNoticeDays"
              type="number"
              title="Maximum days notice"
              name="Maximum days"
              :rules="{ min_value: 1 }"
              @change="$emit('autoSave')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { PRICE_MODELS, DURATION_TYPES } from '@tutti/constants';

export default {
  name: 'VenueCreatePrices',
  props: {
    space: { type: Object, required: true },
    venueType: { type: String, required: true },
  },
  data() {
    return {
      PRICE_MODELS,
      touched: false,
      DURATION_TYPES,
    };
  },
};
</script>
