var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('section', [_c('div', {
    staticClass: "mb-4"
  }, [_c('label', {
    staticClass: "font-bold"
  }, [_vm._v("Select your pricing model*")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "name": "Price Model",
      "items": _vm.PRICE_MODELS,
      "rules": {
        required: _vm.space.bookingVenue !== 'space'
      }
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('autoSave');
      }
    },
    model: {
      value: _vm.space.priceModel,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "priceModel", $$v);
      },
      expression: "space.priceModel"
    }
  })], 1)], 1)], 1), _vm.space.priceModel == 'fixed' ? _c('section', [_c('div', {
    staticClass: "mb-4"
  }, [_c('label', {
    staticClass: "font-bold"
  }, [_vm._v("List your prices below")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("You can have up to 5 prices. Simply add or delete lines as necessary.")])]), _vm._l(_vm.space.prices, function (price, index) {
    return _c('v-row', {
      key: index
    }, [_c('v-col', {
      staticClass: "d-flex pb-0",
      attrs: {
        "cols": "10",
        "sm": "4",
        "md": "2"
      }
    }, [_c('vx-input', {
      staticClass: "mt-2",
      attrs: {
        "type": "checkbox",
        "hide-details": "",
        "dense": ""
      },
      model: {
        value: price.enabled,
        callback: function callback($$v) {
          _vm.$set(price, "enabled", $$v);
        },
        expression: "price.enabled"
      }
    }), _c('vx-input', {
      attrs: {
        "type": "number",
        "label": "Price",
        "rules": {
          required: price.enabled && _vm.space.bookingVenue !== 'space',
          minPrice: {
            price: price
          }
        },
        "prefix": "£",
        "clearable": false,
        "disabled": !price.enabled
      },
      on: {
        "change": function change($event) {
          return _vm.space.onPriceChange(price);
        }
      },
      model: {
        value: price.value,
        callback: function callback($$v) {
          _vm.$set(price, "value", $$v);
        },
        expression: "price.value"
      }
    })], 1), _c('v-col', {
      staticClass: "d-flex pb-0",
      attrs: {
        "cols": "10",
        "sm": "6",
        "md": "2"
      }
    }, [_c('span', {
      staticClass: "mr-5 mt-2"
    }, [_vm._v("per")]), _c('vx-input', {
      staticClass: "w-25",
      attrs: {
        "type": "text",
        "outlined": "",
        "value": _vm.DURATION_TYPES.find(function (type) {
          return type.value === price.type;
        }).text,
        "dense": "",
        "hide-details": "",
        "readonly": "",
        "disabled": !price.enabled
      }
    })], 1), price.type !== 'hour' ? _c('v-col', {
      staticClass: "d-flex pb-0",
      attrs: {
        "cols": "10",
        "sm": "6",
        "md": "4"
      }
    }, [_c('span', {
      staticClass: "mr-5 mt-2"
    }, [_vm._v("This is discount of")]), _c('vx-input', {
      attrs: {
        "type": "number",
        "outlined": "",
        "dense": "",
        "label": "Discount",
        "clearable": false,
        "disabled": !price.enabled,
        "suffix": "%"
      },
      on: {
        "change": function change($event) {
          return _vm.space.calculatePrice(price, $event);
        }
      },
      model: {
        value: price.discount,
        callback: function callback($$v) {
          _vm.$set(price, "discount", $$v);
        },
        expression: "price.discount"
      }
    })], 1) : _vm._e(), _vm.breakpoint.xs && index < _vm.space.prices.length - 1 ? _c('v-col', {
      staticClass: "mb-4",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-divider')], 1) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.space.priceModel == 'quote' ? _c('section', [_c('div', {
    staticClass: "mb-4"
  }, [_c('label', {
    staticClass: "font-bold"
  }, [_vm._v("Minimum spend (public)")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Artists will be more likely to message if they have a rough idea of the minimum they’d be expected to spend and how much that would get them. (ex: £500 for 3 hours; £2000 for 2 days). This will be public to users. ")])]), _vm._l(_vm.space.minimumPrices, function (price, index) {
    return _c('v-row', {
      key: 'minPrice' + index
    }, [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "4",
        "sm": "3",
        "md": "2"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "number",
        "label": "Cost",
        "prefix": "£"
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('autoSave');
        }
      },
      model: {
        value: price.value,
        callback: function callback($$v) {
          _vm.$set(price, "value", $$v);
        },
        expression: "price.value"
      }
    })], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "4",
        "sm": "3",
        "md": "2"
      }
    }, [_vm.breakpoint.mdAndUp ? _c('span', {
      staticClass: "mr-2 mt-2 float-left"
    }, [_vm._v("For :")]) : _vm._e(), _c('vx-input', {
      attrs: {
        "type": "number",
        "prefix": "#"
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('autoSave');
        }
      },
      model: {
        value: price.count,
        callback: function callback($$v) {
          _vm.$set(price, "count", $$v);
        },
        expression: "price.count"
      }
    })], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "4",
        "sm": "3",
        "md": "2"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "select",
        "label": "Time",
        "items": _vm.space.getTimeOptions(price.type, _vm.space.minimumPrices)
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('autoSave');
        }
      },
      model: {
        value: price.type,
        callback: function callback($$v) {
          _vm.$set(price, "type", $$v);
        },
        expression: "price.type"
      }
    })], 1)], 1);
  }), _c('br'), _c('div', {
    staticClass: "mb-4"
  }, [_c('label', {
    staticClass: "font-bold"
  }, [_vm._v("Rough price range (private)")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Price range allows us to show your space when a user sorts by price. We will not share these values with artists. ")])]), _vm._l(_vm.space.roughPrices, function (price, index) {
    return _c('v-row', {
      key: index
    }, [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "sm": "3",
        "md": "2"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "select",
        "label": "Time",
        "items": _vm.space.getTimeOptions(price.type, _vm.space.roughPrices)
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('autoSave');
        }
      },
      model: {
        value: price.type,
        callback: function callback($$v) {
          _vm.$set(price, "type", $$v);
        },
        expression: "price.type"
      }
    })], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "5",
        "sm": "3",
        "md": "2"
      }
    }, [_vm.breakpoint.mdAndUp ? _c('span', {
      staticClass: "mr-2 mt-2 float-left"
    }, [_vm._v(":")]) : _vm._e(), _c('vx-input', {
      attrs: {
        "type": "number",
        "label": "Min",
        "prefix": "£",
        "rules": {
          dynamic_max: {
            price: price
          }
        }
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('autoSave');
        }
      },
      model: {
        value: price.min,
        callback: function callback($$v) {
          _vm.$set(price, "min", $$v);
        },
        expression: "price.min"
      }
    })], 1), _c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "5",
        "sm": "3",
        "md": "2"
      }
    }, [_c('span', {
      staticClass: "mr-2 mt-2 float-left"
    }, [_vm._v("-")]), _c('vx-input', {
      attrs: {
        "type": "number",
        "label": "Max",
        "prefix": "£",
        "rules": {
          dynamic_min: {
            price: price
          }
        }
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('autoSave');
        }
      },
      model: {
        value: price.max,
        callback: function callback($$v) {
          _vm.$set(price, "max", $$v);
        },
        expression: "price.max"
      }
    })], 1), _c('v-col', {
      staticClass: "px-0 pb-0 d-flex",
      attrs: {
        "cols": "2",
        "sm": "2",
        "md": "2"
      }
    }, [_vm.space.roughPrices.length > 1 ? _c('vx-btn', {
      attrs: {
        "icon": "",
        "small": _vm.breakpoint.mdAndDown
      },
      on: {
        "click": function click($event) {
          return _vm.space.removePrice(_vm.space.roughPrices, index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-delete")])], 1) : _vm._e(), _vm.space.roughPrices.length == 1 || _vm.space.roughPrices.length == index + 1 && _vm.space.roughPrices.length < 5 ? _c('vx-btn', {
      attrs: {
        "icon": "",
        "small": _vm.breakpoint.mdAndDown,
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.space.addPrice(_vm.space.roughPrices);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-plus")])], 1) : _vm._e()], 1), _vm.breakpoint.xs && index < _vm.space.roughPrices.length - 1 ? _c('v-col', {
      staticClass: "mb-4",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-divider')], 1) : _vm._e()], 1);
  })], 2) : _vm._e()])], 1), _c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('div', {
    staticClass: "mb-4"
  }, [_c('label', {
    staticClass: "font-bold"
  }, [_vm._v("Overtime charge")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" How much do you charge if an artist goes over the agreed time in your space? (If this happens you will have to charge them directly, but this information is just to keep potential hirers informed ahead of time). ")])]), _vm._l(_vm.space.overtimeCharges, function (price, index) {
    return _c('v-row', {
      key: index
    }, [_c('v-col', {
      staticClass: "pb-0",
      attrs: {
        "cols": "12",
        "sm": "4",
        "md": "2"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "number",
        "label": "Price",
        "prefix": "£"
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('autoSave');
        }
      },
      model: {
        value: price.value,
        callback: function callback($$v) {
          _vm.$set(price, "value", $$v);
        },
        expression: "price.value"
      }
    })], 1), _c('v-col', {
      staticClass: "d-flex pb-0",
      attrs: {
        "cols": "12",
        "sm": "4",
        "md": "2"
      }
    }, [_c('span', {
      staticClass: "mr-5 mt-2"
    }, [_vm._v("per")]), _c('vx-input', {
      staticClass: "w-100",
      attrs: {
        "type": "select",
        "label": "Time",
        "items": _vm.space.getTimeOptions(price.type, _vm.space.overtimeCharges),
        "rules": {
          required: _vm.space.bookingVenue !== 'space'
        }
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('autoSave');
        }
      },
      model: {
        value: price.type,
        callback: function callback($$v) {
          _vm.$set(price, "type", $$v);
        },
        expression: "price.type"
      }
    })], 1)], 1);
  })], 2)], 1), _c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('section', [_c('label', [_vm._v("Minimum booking time")]), _c('div', {
    staticClass: "d-flex mt-1"
  }, [_c('vx-input', {
    attrs: {
      "type": "number",
      "name": "Hours",
      "rules": {
        min_value: 1
      }
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('autoSave');
      }
    },
    model: {
      value: _vm.space.minBookingTimes[0].value,
      callback: function callback($$v) {
        _vm.$set(_vm.space.minBookingTimes[0], "value", $$v);
      },
      expression: "space.minBookingTimes[0].value"
    }
  }), _c('span', {
    staticClass: "mr-2 mt-2 ml-2"
  }, [_vm._v(":")]), _c('vx-input', {
    staticClass: "w-50",
    attrs: {
      "type": "select",
      "items": _vm.space.getTimeOptions(_vm.space.minBookingTimes[0].type, [{
        type: 'half-day'
      }]),
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('autoSave');
      }
    },
    model: {
      value: _vm.space.minBookingTimes[0].type,
      callback: function callback($$v) {
        _vm.$set(_vm.space.minBookingTimes[0], "type", $$v);
      },
      expression: "space.minBookingTimes[0].type"
    }
  })], 1)])]), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "sm": "2"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "number",
      "title": "Minimum days notice",
      "name": "Minimum days",
      "rules": {
        min_value: 1
      }
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('autoSave');
      }
    },
    model: {
      value: _vm.space.minNoticeDays,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "minNoticeDays", $$v);
      },
      expression: "space.minNoticeDays"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "sm": "2"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "number",
      "title": "Maximum days notice",
      "name": "Maximum days",
      "rules": {
        min_value: 1
      }
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('autoSave');
      }
    },
    model: {
      value: _vm.space.maxNoticeDays,
      callback: function callback($$v) {
        _vm.$set(_vm.space, "maxNoticeDays", $$v);
      },
      expression: "space.maxNoticeDays"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }